import { PAGINATION_LIMIT } from '@src/constants';
import api from './api';

const getCustomers = async ({
  limit = PAGINATION_LIMIT,
  page = 1,
  search = '',
  sort = 'createdAt_desc',
  fields,
  searchFields,
}) => {
  try {
    const res = await api({
      method: 'GET',
      url: '/customers',
      params: {
        limit,
        offset: (page - 1) * PAGINATION_LIMIT,
        search,
        fields,
        sort,
        searchFields,
      },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const createCustomer = async ({
  name,
  phoneNumber,
  parentPhoneNumber,
  address,
  birthday,
  gender,
  anamnesis,
  isFamily,
}) => {
  try {
    const res = await api({
      method: 'POST',
      url: '/customers',
      data: {
        name,
        phoneNumber,
        parentPhoneNumber,
        address,
        birthday,
        gender,
        isFamily,
        anamnesis,
      },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const updateCustomer = async ({
  customerId,
  name,
  phoneNumber,
  parentPhoneNumber,
  address,
  birthday,
  gender,
  anamnesis,
  isFamily,
}) => {
  try {
    const res = await api({
      method: 'PUT',
      url: `/customers/${customerId}`,
      data: {
        name,
        phoneNumber,
        address,
        parentPhoneNumber,
        birthday,
        gender,
        anamnesis,
        isFamily,
      },
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const getCustomerById = async (customerId) => {
  try {
    const res = await api({
      method: 'GET',
      url: `/customers/${customerId}`,
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

const removeCustomer = async (customerId) => {
  try {
    const res = await api({
      method: 'DELETE',
      url: `/customers/${customerId}`,
    });

    return res;
  } catch (error) {
    return error.response?.data;
  }
};

export {
  getCustomers,
  createCustomer,
  updateCustomer,
  removeCustomer,
  getCustomerById,
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TestQueue, PageFrame, ProcessHandler } from '@src/components';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { CancelOutlined, Visibility } from '@mui/icons-material';

import { TESTING_STEP, TEST_QUEUE_IN_PAGE } from '@src/constants';
import { calculateAge } from '@src/utils';

import { StyledTesting } from './index.style';
import useCustomerTesting from './useCustomerTesting';
import TestStep from './TestStep';
import DiagnoseStep from './DiagnoseStep';
import ServiceStep from './ServiceStep';
import ConfirmOrderStep from './ConfirmOrderStep';
import CancelTestingDialog from './CancelTestingDialog';
import DetailCustomerDialog from './DetailCustomerDialog';

const initialCancelTesting = {
  testingId: null,
  customerId: null,
  customerName: '',
};

const Testing = () => {
  const testStepRef = useRef();

  const [testQueues, setTestQueues] = useState([]);
  const [activeCustomer, setActiveCustomer] = useState();
  const [hasChanged, setHasChanged] = useState(false);
  const [cancelTesting, setCancelTesting] = useState(initialCancelTesting);
  const [openDetailCustomer, setOpenDetailCustomer] = useState(false);

  const {
    testing,
    order,
    reTestDate,
    activeStep,
    fetchLoading,
    fetchInprogressTesting,
    writeDiagnoseToTesting,
    createTesting,
    updateTesting,
    setOrder,
    setTesting,
    setReTestDate,
    setActiveStep,
  } = useCustomerTesting();

  const renderCustomerText = () => {
    if (!activeCustomer) return '';

    const { name, phoneNumber, address, birthday } = activeCustomer;
    let result = `${name}`;
    if (birthday) result += ` - ${calculateAge(birthday)} tuổi`;
    if (phoneNumber) result += ` - ${phoneNumber}`;
    if (address) result += ` - ${address}`;
    return result;
  };

  const handleSubmitDiagnose = async (diagnose, medicines, note) => {
    writeDiagnoseToTesting(medicines, diagnose, note);
  };

  const handleCreateTesting = async ({
    customerId,
    indicators,
    reason,
    draft = false,
  }) => {
    const done = await createTesting({ customerId, indicators, reason, draft });

    if (!done) return;

    // update status customer is testing in testQueues
    const tmpTestQueues = testQueues.map((item) => {
      if (item.customer.id !== customerId) return item;
      return { ...item, isTesting: true };
    });
    setTestQueues(tmpTestQueues);
  };

  const handleUpdateTesting = ({ reason, indicators, draft = false }) => {
    updateTesting(reason, indicators, draft);
  };

  const handlePickWaitingCustomer = (customer) => {
    if (hasChanged) {
      if (activeStep === TESTING_STEP.TEST) {
        testStepRef.current.updateTesting();
      } else if (!activeStep && !testing) {
        testStepRef.current.createTesting();
      }
    }
    setActiveCustomer(customer);
    setHasChanged(false);
    setOrder();
    setTesting();
    setActiveStep();
  };

  const handleCreateOrderSuccess = (newOrder) => {
    setActiveStep(TESTING_STEP.CONFIRM_ORDER);
    setOrder(newOrder);
  };

  const handleEndTest = (customerId) => {
    setTesting();
    setOrder();

    const newTestQueues = testQueues.filter(
      (item) => item.customer?.id !== customerId,
    );
    setTestQueues(newTestQueues);
    setActiveCustomer();
    setActiveStep();
  };

  const handleOpenCancelTesting = () => {
    setCancelTesting({
      testingId: testing.id,
      customerId: testing?.customer?.id,
      customerName: testing?.customer?.name,
    });
  };

  const handleCancelSuccessTesting = () => {
    handleEndTest(cancelTesting.customerId);
    setCancelTesting(initialCancelTesting);
  };

  const handleCloseCancelTesting = () => {
    setCancelTesting(initialCancelTesting);
  };

  const handleOpenDetailCustomer = () => {
    setOpenDetailCustomer(true);
  };

  const handleBackToStep = (step) => setActiveStep(step);

  const renderStep = useCallback(() => {
    switch (activeStep) {
      case TESTING_STEP.TEST:
        return (
          <TestStep
            ref={testStepRef}
            testing={testing}
            customerId={activeCustomer?.id}
            onUpdateTesting={handleUpdateTesting}
            setHasChanged={setHasChanged}
          />
        );
      case TESTING_STEP.DIAGNOSE:
        return (
          <DiagnoseStep
            testing={testing}
            reTestDate={reTestDate}
            setReTestDate={setReTestDate}
            onSubmitDiagnose={handleSubmitDiagnose}
            onBackStep={handleBackToStep}
          />
        );
      case TESTING_STEP.GLASS_MEDICINE_SERVICE:
        return (
          <ServiceStep
            testing={testing}
            order={order}
            activeCustomerId={activeCustomer?.id}
            onCreateOrderSuccess={handleCreateOrderSuccess}
            onBackStep={handleBackToStep}
          />
        );
      case TESTING_STEP.CONFIRM_ORDER:
        return (
          <ConfirmOrderStep
            customerId={activeCustomer?.id}
            testing={testing}
            order={order}
            reTestDate={reTestDate}
            onEndTest={handleEndTest}
            onBackStep={handleBackToStep}
          />
        );
      default:
        return (
          <TestStep
            ref={testStepRef}
            testing={testing}
            customerId={activeCustomer?.id}
            onCreateTesting={handleCreateTesting}
            setHasChanged={setHasChanged}
          />
        );
    }
  }, [activeStep, activeCustomer?.id, testing, order, reTestDate]);

  useEffect(() => {
    if (!activeCustomer?.id) return;
    setTesting();
    fetchInprogressTesting(activeCustomer.id);
  }, [activeCustomer?.id]);

  useEffect(() => {
    if (testQueues.length > 0 && !activeCustomer)
      setActiveCustomer(testQueues[0].customer);
  }, [testQueues, activeCustomer]);

  const showCancelTesting =
    [
      TESTING_STEP.WAITING,
      TESTING_STEP.TEST,
      TESTING_STEP.DIAGNOSE,
      TESTING_STEP.GLASS_MEDICINE_SERVICE,
    ].includes(activeStep) &&
    testing?.customer?.testingStep !== TESTING_STEP.CONFIRM_ORDER;

  return (
    <PageFrame title="Khám Bệnh">
      <StyledTesting>
        <TestQueue
          queue={testQueues}
          activeCustomerId={activeCustomer?.id}
          inPage={TEST_QUEUE_IN_PAGE.TESTING}
          setQueue={setTestQueues}
          onPick={handlePickWaitingCustomer}
        />
        <div className="current-testing">
          <Typography>
            Thông tin khách hàng đang khám: <b>{renderCustomerText()}</b>
          </Typography>
          <Box>
            <Tooltip title="Thông tin chi tiết">
              <IconButton onClick={handleOpenDetailCustomer}>
                <Visibility />
              </IconButton>
            </Tooltip>
            {showCancelTesting && (
              <Tooltip title="Huỷ khám">
                <IconButton color="secondary" onClick={handleOpenCancelTesting}>
                  <CancelOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <CancelTestingDialog
            testingId={cancelTesting.testingId}
            onCanceledSuccess={handleCancelSuccessTesting}
            onClose={handleCloseCancelTesting}
          />
          <DetailCustomerDialog
            open={openDetailCustomer}
            customer={activeCustomer}
            onClose={() => setOpenDetailCustomer(false)}
          />
        </div>
        <Divider />
        <ProcessHandler mt="100px" align="center" loading={fetchLoading}>
          {renderStep()}
        </ProcessHandler>
      </StyledTesting>
    </PageFrame>
  );
};

export default Testing;

import { TESTING_STATUS } from '@src/constants';

const renderDiagnoseText = (testing) => {
  const { leftEye, rightEye, general } = testing?.testing?.diagnose;

  if (testing.testing.status === TESTING_STATUS.DONE) {
    return `${leftEye ? ` Mắt trái: ${leftEye} - ` : ''} 
    ${rightEye ? ` Mắt phải: ${rightEye} - ` : ''} 
    ${general ? ` Chung: ${general}` : ''}`;
  }

  return 'Không có chuẩn đoán';
};

export { renderDiagnoseText };

import React, { useState, useEffect } from 'react';
import {
  PageFrame,
  CustomTable,
  StatusCard,
  orderTypeCards,
  ProcessHandler,
} from '@src/components';
import { useHistory } from 'react-router-dom';
import apis from '@src/apis';
import { Box, Divider, IconButton, TextField, Tooltip } from '@mui/material';
import { ROUTES } from '@src/constants/route';
import CustomDatePickerRange from '@src/components/CustomDatePickerRangeNew';
import { Delete, Visibility } from '@mui/icons-material';
import { convertFormatDate, debounce, delimitNumber } from '@src/utils';
import { ORDER_STATUS } from '@src/constants';
import SelectOption from '@src/components/SelectOption/SelectOption';
import { StyledOrder } from './index.style';
import DeleteOrderDialog from './DeleteOrderDialog';

const initialCreatedAt = () => {
  const endDate = new Date();
  const startDate = null;

  return [startDate, endDate];
};

const orderStatusOption = {
  key: 'orderStatusOption',
  name: 'orderStatusOption',
  title: 'Lọc trạng thái',
  options: [
    { value: ORDER_STATUS.All, label: 'Tất cả' },
    { value: ORDER_STATUS.PENDING, label: 'Đang xử lý' },
    { value: ORDER_STATUS.PAID, label: 'Hoàn thành' },
    { value: ORDER_STATUS.CANCELLED, label: 'Hủy' },
  ],
};

const optionsDatePicker = [
  { label: 'Hôm qua', value: -1 },
  { label: '3 ngày trước', value: -3 },
  { label: 'Tuần trước', value: -7 },
  { label: 'Tháng trước', value: -30 },
];

const initialDeleteOrder = {
  orderId: null,
  customerName: '',
  orderStatus: '',
};

const Order = () => {
  const history = useHistory();

  const [orders, setOrders] = useState([]);
  const [paging, setPaging] = useState({ total: 0, page: 1 });
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [firstLoadComponent, setFirstLoadComponent] = useState(true);
  const [deletingOrder, setDeletingOrder] = useState(initialDeleteOrder);

  const [queryDate, setQueryDate] = useState(initialCreatedAt);

  const handleTurnToDetail = (orderId) => {
    history.push(`${ROUTES.ORDER}/${orderId}`);
  };

  const handleChangeDatePickerRange = (value) => {
    setQueryDate(value);
  };

  const handleResetFilterDate = () => {
    setQueryDate(initialCreatedAt());
  };

  const handleOpenDeleteOrderDialog = (order) => {
    if (!order) return;
    setDeletingOrder({
      orderId: order.id,
      customerName: order.customer?.name,
      orderStatus: order.status,
    });
  };

  const resetDeletingOrder = () => {
    setDeletingOrder(initialDeleteOrder);
  };

  const columns = [
    {
      field: 'customer-name',
      align: 'left',
      id: 'name',
      title: 'Tên khách hàng',
      render: (order) => order.customer?.name,
    },
    {
      field: 'customer-phoneNumber',
      align: 'left',
      id: 'phoneNumber',
      title: 'Số điện thoại',
      render: (order) => {
        if (order?.customer?.phoneNumber) {
          return `${order?.customer?.phoneNumber}`;
        }
        if (order?.customer?.parentPhoneNumber) {
          return `SDT người thân: ${order?.customer?.parentPhoneNumber}`;
        }
        return 'Không có';
      },
    },
    {
      field: 'customer-address',
      align: 'left',
      id: 'address',
      title: 'Địa chỉ',
      render: (order) => order.customer?.address,
    },
    {
      field: 'status',
      align: 'center',
      id: 'status',
      title: 'Tình trạng thanh toán',
      render: (order) => (
        <Box fullWidth display="flex" justifyContent="center">
          <StatusCard {...orderTypeCards[order.status]} />
        </Box>
      ),
    },
    {
      field: 'discount',
      align: 'center',
      id: 'discount',
      title: 'Chiết khấu',
      render: (order) => `${order.discount}%`,
    },
    {
      field: 'revenue',
      align: 'left',
      id: 'revenue',
      title: 'Doanh thu',
      render: (order) => delimitNumber(order.revenue),
    },
    {
      field: 'createAt',
      align: 'left',
      id: 'createAt',
      title: 'Ngày tạo',
      render: (order) => convertFormatDate(order.createdAt),
    },
    {
      field: 'updatedAt',
      align: 'left',
      id: 'updatedAt',
      title: 'Ngày sửa',
      render: (order) => convertFormatDate(order.updatedAt),
    },
    {
      id: 'actions',
      align: 'center',
      title: 'Hành động',
      render: (item) => (
        <>
          <Tooltip title="Xem chi tiết">
            <IconButton onClick={() => handleTurnToDetail(item.id)}>
              <Visibility />
            </IconButton>
          </Tooltip>
          {[ORDER_STATUS.PAID, ORDER_STATUS.CANCELLED].includes(
            item.status,
          ) && (
            <Tooltip title="Xóa">
              <IconButton onClick={() => handleOpenDeleteOrderDialog(item)}>
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const handleDeletedOrder = async (orderId) => {
    const newOrders = orders.filter((item) => item.id !== orderId);
    setOrders(newOrders);
    setDeletingOrder(initialDeleteOrder);
  };

  const inputSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleChangeStatusOrder = (name, value) => {
    if (value === ORDER_STATUS.All) {
      setStatus(null);
      return;
    }

    setStatus(value);
  };

  const fetchOrders = async () => {
    setLoadingSearch(true);
    const res = await apis.orders.getOrders({
      search: searchValue,
      page: paging.page,
      startDate: queryDate[0],
      endDate: queryDate[1],
      status,
    });
    setLoadingSearch(false);

    if (!res?.status) return;

    setOrders(res.result.orders);
    setPaging({ ...paging, total: res.result.total });
  };

  const handleChangePage = (page) => {
    setPaging((prevState) => ({ ...prevState, page }));
  };

  useEffect(() => {
    if (!firstLoadComponent) debounce(fetchOrders, 500)(searchValue);
  }, [searchValue]);

  useEffect(() => {
    fetchOrders();
  }, [paging.page, queryDate, status]);

  useEffect(() => {
    setFirstLoadComponent(false);
  }, []);

  return (
    <PageFrame title="Hóa đơn">
      <StyledOrder>
        <Divider />
        <div className="filter-orders">
          <div className="filter-choose">
            <SelectOption
              className="filter-status"
              key={orderStatusOption.key}
              title={orderStatusOption.title}
              name={orderStatusOption.name}
              options={orderStatusOption.options}
              onChange={handleChangeStatusOrder}
            />
            <div className="filter-dateRange">
              <CustomDatePickerRange
                value={queryDate}
                optionsDatePicker={optionsDatePicker}
                isRefresh
                onChange={handleChangeDatePickerRange}
                handleRefresh={handleResetFilterDate}
              />
            </div>
          </div>
          <div className="search">
            <TextField
              className="input-search"
              size="small"
              placeholder="Tìm kiếm hóa đơn"
              onChange={inputSearchChange}
              InputProps={{
                endAdornment: loadingSearch ? (
                  <ProcessHandler loading size={20} />
                ) : null,
              }}
            />
          </div>
        </div>
        <Divider />
        <CustomTable
          columns={columns}
          data={orders}
          total={paging.total}
          page={paging.page}
          onChangePage={handleChangePage}
        />
        <DeleteOrderDialog
          open={!!deletingOrder.orderId}
          orderId={deletingOrder.orderId}
          orderStatus={deletingOrder.orderStatus}
          customerName={deletingOrder.customerName}
          onClose={resetDeletingOrder}
          onDeletedSuccess={handleDeletedOrder}
        />
      </StyledOrder>
    </PageFrame>
  );
};
export default Order;

import { ERROR_CODE } from './code';

export const getMessage = (code, msg) => {
  switch (code) {
    case ERROR_CODE.UNAUTHORIZED:
      return 'Phiên đăng nhập đã kết thúc, vui lòng đăng nhập lại!';
    case ERROR_CODE.BAD_REQUEST:
      return 'Yêu cầu không hợp lệ, vui lòng kiểm tra lại nội dung trước khi gửi';
    case ERROR_CODE.EXISTING_CUSTOMER:
      return 'Số điện thoại đã tồn tại';
    case ERROR_CODE.NOT_FOUND_CUSTOMER:
      return 'Không tìm thấy khách hàng';
    case ERROR_CODE.EXISTING_TEST_QUEUE:
      return 'Khách hàng đã tồn tại trong hàng chờ';
    case ERROR_CODE.CUSTOMER_IN_FAMILY:
      return 'Số điện thoại này hiện đang có nhiều khách hàng trong 1 gia đình nên không thể thay đổi';
    case ERROR_CODE.IS_FAMILY_CUSTOMER_WRONG:
      return 'Số điện thoại này đã tồn tại dưới dạng "gia đình", xin hãy kiểm tra lại dữ liệu hoặc để tiếp tục tạo, xin hãy chọn "Đi gia đình"';
    case ERROR_CODE.NOT_FOUND_TEST_QUEUE:
      return 'Không tìm thấy khách hàng trong hàng chờ.';
    case ERROR_CODE.TESTING_IN_PROGRESS:
      return 'Khách hàng này đang trong quá trình khám, không thể tiếp tục tạo đơn khám mới cho tới khi hoàn thành';
    case ERROR_CODE.CUSTOMER_HAD_TESTED:
      return 'Khách hàng này đã được khám. Không thể xóa!';
    case ERROR_CODE.CUSTOMER_IN_QUEUE:
      return 'Khách hàng này đang trong hàng chờ khám. Không thể xóa!';
    case ERROR_CODE.CANCELED_ORDER:
      return 'Hóa đơn đã bị hủy';
    case ERROR_CODE.PAID_ORDER:
      return 'Hóa đơn đã được thanh toán';
    case ERROR_CODE.NOT_FOUND_FACILITY:
      return 'Không tìm thấy cơ sở.';
    case ERROR_CODE.NOT_FOUND_ACCOUNT:
      return 'Không tìm thấy tài khoản';
    case ERROR_CODE.EXISTING_ACCOUNT:
      return 'Tài khoản đã tồn tại';
    case ERROR_CODE.PASSWORD_NOT_MATCH:
      return 'Mật khẩu không chính xác';
    case ERROR_CODE.CREATED_ORDER:
      return 'Hóa đơn đã được tạo trên đơn khám này';
    case ERROR_CODE.PRODUCT_EXISTING_IN_ORDER:
      return 'Sản phẩm này đã tồn tại trong hóa đơn. Không thể xóa';
    case ERROR_CODE.RETEST_DATE_IS_DONE:
      return 'Lich tái khám này đã hoàn thành. Không thể xóa';
    case ERROR_CODE.RETEST_DATE_IS_CANCELLED:
      return 'Lich tái khám này đã bị hủy. Không thể xóa';
    case ERROR_CODE.NOT_FOUND_RETESTDATE:
      return 'Không thể tìm thấy lịch tái khám này';
    case ERROR_CODE.RETEST_DATE_IN_PENDING:
      return 'Đã tồn tại lịch hẹn tái khám đang chờ';
    case ERROR_CODE.EXISTING_CHATID:
      return 'Tài khoản này đã tồn tại chatID';
    case ERROR_CODE.INVALID_CHATID:
      return 'ChatID không hợp lệ vui lòng nhập lại chatID';
    case ERROR_CODE.EXISTING_PRODUCT:
      return 'Tên sản phẩm này đã tồn tại';
    case ERROR_CODE.NOT_ALLOWED_CANCEL_TESTING:
      return 'Không cho phép huỷ quá trình khám này, do đang ở bước xác nhận đơn hàng.';
    case ERROR_CODE.NOT_COMPLETED_ORDER:
      return 'Không cho phép xoá đơn hàng vì đơn hàng chưa kết thúc (Đã thanh toán hoặc Đã huỷ).';
    case ERROR_CODE.TOO_LATE_TO_DELETE_ORDER:
      return 'Quá trễ để xoá đơn hàng này. Chúng tôi chỉ cho phép xoá đơn hàng trong vòng 1 ngày kể từ khi đơn hàng kết thúc.';
    default:
      return msg || 'Có lỗi xảy ra, vui lòng thử lại sau';
  }
};

import React, { useEffect, useState } from 'react';
import DiagnoseStep from '@src/containers/Testing/DiagnoseStep';
import { Button } from '@mui/material';
import { ProcessHandler } from '@src/components';
import { RETEST_DATE_TYPE } from '@src/constants';
import apis from '@src/apis';
import { useDispatch } from 'react-redux';
import actions from '@src/redux/actions';
import { mapPrescription } from '@src/containers/Testing/useCustomerTesting';
import dayjs from 'dayjs';
import { StyledUpdateTestingDialog } from '../index.style';

const initialData = {
  prescription: [],
  note: '',
};

const initialReTestDate = {
  date: new Date(),
  type: RETEST_DATE_TYPE.SELECT,
  optionValue: 0,
};

const Product = ({ testing, reTestDate, onUpdateOrderSuccess, onClose }) => {
  const [updateTesting, setUpdateTesting] = useState(initialData);
  const [updateReTestDate, setUpdateReTestDate] = useState(initialReTestDate);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const shouldUpdateReTestDate = (existed, nowObj) => {
    if (!existed) return false;
    const isDiff = dayjs(existed.date).diff(nowObj.date, 'day');

    return !(existed.type === nowObj.type && !isDiff);
  };

  const handleSubmitReTestDate = async () => {
    let execFunc;
    const shouldUpdate = shouldUpdateReTestDate(reTestDate, updateReTestDate);

    if (reTestDate && shouldUpdate) {
      execFunc = apis.retestDates.updateReTestDate;
    }
    if (!reTestDate && updateReTestDate.optionValue !== 0) {
      execFunc = apis.retestDates.createRetestDate;
    }

    if (!execFunc) {
      return {
        status: 1, // do nothing when not selecting reTestDate
      };
    }

    const payload = {
      ...updateReTestDate,
      customerId: testing.customer,
      testingId: testing.id,
      reTestDateId: reTestDate?.id,
      status: reTestDate?.status,
    };

    const res = await execFunc(payload);
    return res;
  };

  const handleCheckingValidMedicines = (medicines) => {
    const invalidItems = medicines.filter((item) => !item?.instruction);
    if (invalidItems.length > 0) {
      return invalidItems.map((item) => item.name).join(', ');
    }

    return false;
  };

  const handleCanUpdateOrder = (newMedicine, status) => {
    const tempPrescription = updateTesting.prescription?.map((item) => {
      if (
        item.productId === newMedicine?.id ||
        item.name === newMedicine?.name
      ) {
        return {
          ...item,
          isUpdateOrder: status,
        };
      }
      return item;
    });

    setUpdateTesting((prev) => ({
      ...prev,
      prescription: tempPrescription,
    }));
  };

  const handleUpdateOrderTestingProduct = async () => {
    const invalidMedicinesName = handleCheckingValidMedicines(
      updateTesting?.prescription,
    );
    if (invalidMedicinesName) {
      dispatch(
        actions.noti.push({
          message: `${invalidMedicinesName} đang chưa được điền chỉ dẫn`,
          severity: 'error',
        }),
      );
      return;
    }
    updateTesting?.prescription.forEach((item) => {
      if (!item?.instruction) {
        dispatch(
          actions.noti.push({
            message: 'Cập nhật đơn thuốc không thành công',
            severity: 'error',
          }),
        );
      }
    });

    setLoading(true);
    const [resTesting, resReTestDate] = await Promise.all([
      apis.testings.updateTestingExamination({
        testingId: testing.id,
        prescription: mapPrescription(updateTesting?.prescription),
        note: updateTesting?.note,
      }),
      handleSubmitReTestDate(),
    ]);
    setLoading(false);

    if (!resTesting?.status || !resReTestDate?.status) {
      dispatch(
        actions.noti.push({
          message: 'Cập nhật đơn thuốc không thành công',
          severity: 'error',
        }),
      );
      return;
    }

    dispatch(
      actions.noti.push({
        message: 'Cập nhật đơn thuốc thành công',
        severity: 'success',
      }),
    );
    onUpdateOrderSuccess();
  };

  useEffect(() => {
    if (reTestDate) {
      setUpdateReTestDate(reTestDate);
    }
    if (testing) {
      setUpdateTesting(testing);
    }
  }, [testing, reTestDate]);

  return (
    <StyledUpdateTestingDialog>
      <DiagnoseStep
        testing={updateTesting}
        reTestDate={reTestDate}
        canUpdateOrder
        setReTestDate={setUpdateReTestDate}
        isShowDiagnose={false}
        isShowNavigateButton={false}
        onCanUpdateOrder={handleCanUpdateOrder}
        onUpdateOrderTestingProduct={setUpdateTesting}
        onReTestDateChange={setUpdateReTestDate}
      />
      <div className="groupButton">
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          Huỷ
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={handleUpdateOrderTestingProduct}
        >
          <ProcessHandler loading={loading} size={25}>
            Lưu
          </ProcessHandler>
        </Button>
      </div>
    </StyledUpdateTestingDialog>
  );
};

export default Product;

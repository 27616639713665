import React, { useState, useEffect } from 'react';
import {
  ConfirmDialog,
  CustomTable,
  PageFrame,
  ProcessHandler,
  StatusCard,
  retestDateStatusCard,
} from '@src/components';
import { Box, Divider, IconButton, TextField, Tooltip } from '@mui/material';
import CustomDatePickerRangeNew from '@src/components/CustomDatePickerRangeNew';
import { calculateAge, convertFormatDate, debounce } from '@src/utils';
import { Edit, Delete } from '@mui/icons-material';
import apis from '@src/apis';
import SelectOption from '@src/components/SelectOption/SelectOption';
import { RETEST_DATE } from '@src/constants';
import actions from '@src/redux/actions';
import { useDispatch } from 'react-redux';

import { StyledRetestDate } from './index.style';
import ReTestDateDialog from './ReTestDateDialog';

const optionsDatePicker = [
  { label: 'Hôm nay', value: 0 },
  { label: '1 ngày sau', value: 1 },
  { label: '2 ngày sau', value: 2 },
  { label: '3 ngày sau', value: 3 },
  { label: '1 tuần sau', value: 7 },
  { label: '1 tháng sau', value: 30 },
];

const reTestDateOption = {
  key: 'reTestDateOption',
  name: 'reTestDateOption',
  title: 'Lọc trạng thái',
  options: [
    { value: RETEST_DATE.All, label: 'Tất cả' },
    { value: RETEST_DATE.PENDING, label: 'Đang xử lý' },
    { value: RETEST_DATE.DONE, label: 'Hoàn thành' },
    { value: RETEST_DATE.CANCELLED, label: 'Hủy' },
  ],
};

const initialCreatedAt = () => {
  const endDate = null;
  const startDate = null;
  return [startDate, endDate];
};

const RetestDate = () => {
  const dispatch = useDispatch();

  const [retestDates, setRetestDate] = useState([]);
  const [paging, setPaging] = useState({ total: 0, page: 1 });
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [queryDate, setQueryDate] = useState(initialCreatedAt());
  const [textSearch, setTextSearch] = useState(null);
  const [status, setStatus] = useState(null);
  const [deletingReTestDateId, setDeletingReTestDateId] = useState('');
  const [firstLoadComponent, setFirstLoadComponent] = useState(true);
  const [updateReTestDate, setUpdateReTestDate] = useState(null);

  const columns = [
    {
      field: 'customer-name',
      align: 'left',
      id: 'customer-name',
      title: 'Tên khách hàng',
      render: (reTestDate) => reTestDate.customer?.name,
    },
    {
      field: 'customer-PhoneNumber',
      align: 'center',
      id: 'customer-PhoneNumber',
      title: 'Số điện thoại',
      render: (reTestDate) => {
        if (reTestDate?.customer?.phoneNumber) {
          return `${reTestDate?.customer?.phoneNumber}`;
        }
        if (reTestDate?.customer?.parentPhoneNumber) {
          return `SDT người thân: ${reTestDate?.customer?.parentPhoneNumber}`;
        }
        return 'Không có';
      },
    },
    {
      field: 'customer-address',
      align: 'center',
      id: 'customer-address',
      title: 'Địa chỉ',
      render: (reTestDate) => reTestDate.customer?.address,
    },
    {
      field: 'customer-age',
      align: 'center',
      id: 'customer-age',
      title: 'Tuổi',
      render: (reTestDate) => calculateAge(reTestDate.customer?.birthday),
    },
    {
      field: 'reTestDate',
      align: 'center',
      id: 'reTestDate',
      title: 'Ngày tái khám',
      render: (reTestDate) => convertFormatDate(reTestDate.date, 'DD-MM-YYYY'),
    },
    {
      field: 'status',
      align: 'center',
      id: 'status',
      title: 'Trạng thái',
      render: (retestDate) => (
        <Box fullWidth display="flex" justifyContent="center">
          <StatusCard {...retestDateStatusCard[retestDate.status]} />
        </Box>
      ),
    },
    {
      id: 'actions',
      align: 'center',
      title: 'Hành động',
      render: (retestDate) => (
        <>
          <Tooltip title="Xóa">
            <IconButton
              onClick={() => {
                setDeletingReTestDateId(retestDate.id);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Chỉnh sửa">
            <IconButton
              onClick={() => {
                setUpdateReTestDate(retestDate);
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const searchChangeHandle = (e) => {
    setTextSearch(e.target.value);
  };

  const handleChangeDatePickerRange = (value) => {
    setQueryDate(value);
  };

  const handleResetFilterDate = () => {
    setQueryDate(initialCreatedAt());
  };

  const handleCloseUpdateDialog = () => {
    setUpdateReTestDate(null);
  };

  const handleUpdatedReTestDate = (reTestDateUpdate) => {
    const tmReTestDates = retestDates.map((item) => {
      if (item.id === reTestDateUpdate.id) return reTestDateUpdate;
      return item;
    });

    setRetestDate(tmReTestDates);
  };

  const fetchRetestDate = async () => {
    setLoadingSearch(true);

    const res = await apis.retestDates.getReTestDates({
      page: paging.page,
      dateField: 'date',
      search: textSearch,
      startDate: queryDate[0],
      endDate: queryDate[1],
      status,
    });
    setLoadingSearch(false);

    if (!res?.status) return;

    setRetestDate(res.result.reTestDates);
    setPaging({ ...paging, total: res.result.total });
  };

  const handleChangePage = (page) => {
    setPaging((prevState) => ({ ...prevState, page }));
  };

  const handleCloseDeleteDialog = () => {
    setDeletingReTestDateId('');
  };

  const handleChangeStatus = (name, value) => {
    if (value === RETEST_DATE.All) {
      setStatus(null);
      return;
    }

    setStatus(value);
  };

  const handleDeleteReTestDate = async () => {
    const res = await apis.retestDates.removeReTestDate(deletingReTestDateId);

    if (!res?.status) {
      dispatch(
        actions.noti.push({
          code: res?.code || 0,
          severity: 'error',
          message: res?.message,
        }),
      );
      return;
    }
    dispatch(
      actions.noti.push({
        message: 'Xóa lịch tái khám thành công',
        severity: 'success',
      }),
    );

    setRetestDate((prev) =>
      prev.filter((reTestDate) => reTestDate.id !== deletingReTestDateId),
    );
    setDeletingReTestDateId('');
  };

  useEffect(() => {
    if (!firstLoadComponent) debounce(fetchRetestDate, 500)(textSearch);
  }, [textSearch]);

  useEffect(() => {
    fetchRetestDate();
  }, [paging.page, queryDate, status]);

  useEffect(() => {
    setFirstLoadComponent(false);
  }, []);

  return (
    <PageFrame title="Lịch tái khám">
      <StyledRetestDate>
        <Divider />
        <div className="filter">
          <div className="option-filter-date">
            <SelectOption
              className="filter-status"
              key={reTestDateOption.key}
              title={reTestDateOption.title}
              name={reTestDateOption.name}
              options={reTestDateOption.options}
              onChange={handleChangeStatus}
            />
            <div className="filter-dateRange">
              <CustomDatePickerRangeNew
                value={queryDate}
                isRefresh
                optionsDatePicker={optionsDatePicker}
                onChange={handleChangeDatePickerRange}
                handleRefresh={handleResetFilterDate}
              />
            </div>
          </div>

          <div className="filter-search">
            <TextField
              className="input-search"
              size="small"
              placeholder="Tìm kiếm khách hàng"
              onChange={searchChangeHandle}
              InputProps={{
                endAdornment: loadingSearch ? (
                  <ProcessHandler loading size={20} />
                ) : null,
              }}
            />
          </div>
        </div>

        <CustomTable
          columns={columns}
          data={retestDates}
          total={paging.total}
          page={paging.page}
          onChangePage={handleChangePage}
        />

        <ReTestDateDialog
          open={Boolean(updateReTestDate)}
          reTestDate={updateReTestDate}
          onClose={handleCloseUpdateDialog}
          onUpdateReTestDate={handleUpdatedReTestDate}
        />
      </StyledRetestDate>
      <ConfirmDialog
        open={Boolean(deletingReTestDateId)}
        title="Xóa lịch hẹn khám"
        description="Bạn có muốn xóa lịch hẹn khám này?"
        titleOk="Đồng ý"
        titleCancel="Hủy"
        onCancel={handleCloseDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onOk={handleDeleteReTestDate}
      />
    </PageFrame>
  );
};

export default RetestDate;

import { Box, Button, IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  BaseDialog,
  Card,
  CustomTable,
  orderTypeCards,
  retestDateStatusCard,
  StatusCard,
} from '@src/components';
import apis from '@src/apis';
import { useDispatch } from 'react-redux';
import actions from '@src/redux/actions';

import { Visibility, Check, Close, Edit } from '@mui/icons-material';
import { GENDER, RETEST_DATE } from '@src/constants';
import { calculateAge, convertFormatDate, delimitNumber } from '@src/utils';
import { StyledCustomer } from '../Customer/index.style';
import { StyledButtonGroup } from './index.style';
import { renderDiagnoseText } from '../Customer/util';
import ReTestDateDialog from '../RetestDate/ReTestDateDialog';

const DetailCustomerDialog = ({ open, customer = {}, onClose }) => {
  const [orders, setOrders] = useState([]);
  const [paging, setPaging] = useState({ total: 0, page: 1 });
  const [updateReTestDate, setUpdateReTestDate] = useState();

  const dispatch = useDispatch();

  const fetchTestingsByCustomerId = async () => {
    const res = await apis.orders.getOrderByCustomerId(customer.id, {
      page: paging.page,
    });
    if (!res?.status) return;

    setOrders(res.result.orders);
    setPaging({ ...paging, total: res.result.total });
  };

  const updateRetestDate = async (reTestDate, status) => {
    const res = await apis.retestDates.updateReTestDate({
      reTestDateId: reTestDate.id,
      date: reTestDate.date,
      type: reTestDate.type,
      optionValue: reTestDate.optionValue,
      status,
    });
    if (!res?.status) {
      dispatch(
        actions.noti.push({
          code: res?.code,
          message: res?.message,
          severity: 'error',
        }),
      );
      return;
    }

    dispatch(
      actions.noti.push({
        message: 'Cập nhật trạng thái lịch khám thành công',
        severity: 'success',
      }),
    );
    const retestDate = res.result;

    const newOrders = orders.map((order) => {
      if (order.retestDate?.id !== retestDate.id) return order;
      return { ...order, retestDate };
    });

    setOrders(newOrders);
  };

  const handleDoneRetestDate = async (reTestDate) => {
    updateRetestDate(reTestDate, RETEST_DATE.DONE);
  };

  const handleCancelRetestDate = async (reTestDate) => {
    updateRetestDate(reTestDate, RETEST_DATE.CANCELLED);
  };

  const handleViewDetailOrder = (orderId) => {
    window.open(`/hoa-don/${orderId}`, '_blank');
  };

  const columns = [
    {
      field: 'status',
      align: 'left',
      id: 'status',
      title: 'Trạng thái đơn',
      render: (order) => (
        <Box display="flex">
          <StatusCard {...orderTypeCards[order.status]} />
        </Box>
      ),
    },
    {
      field: 'reason',
      align: 'left',
      id: 'reason',
      title: 'Lý do khám',
      render: (order) => order.testing?.reason,
    },
    {
      field: 'createdAt',
      align: 'left',
      id: 'createdAt',
      title: 'Ngày khám',
      render: (order) => convertFormatDate(order.createdAt, 'HH:mm DD-MM-YYYY'),
    },
    {
      field: 'diagnose',
      align: 'left',
      id: 'diagnose',
      title: 'Chuẩn đoán',
      render: (order) => (
        <div className="diagnose">{renderDiagnoseText(order)}</div>
      ),
    },
    {
      field: 'reTestDate',
      align: 'left',
      id: 'reTestDate',
      title: 'Ngày hẹn khám',
      render: (order) =>
        order.retestDate
          ? convertFormatDate(order.retestDate?.date, 'DD-MM-YYYY')
          : '',
    },
    {
      field: 'reTestDate',
      align: 'left',
      id: 'reTestDate status',
      title: 'Trạng thái hẹn khám',
      render: (order) => (
        <Box display="flex">
          <StatusCard {...retestDateStatusCard[order.retestDate?.status]} />
        </Box>
      ),
    },
    {
      id: 'actions',
      title: 'Hành động',
      align: 'center',
      render: (order) => (
        <>
          <Tooltip title="Thông tin chi tiết">
            <IconButton onClick={() => handleViewDetailOrder(order.id)}>
              <Visibility />
            </IconButton>
          </Tooltip>
          {order.retestDate?.status === RETEST_DATE.PENDING && (
            <>
              <Tooltip title="Đã khám theo lịch">
                <IconButton
                  onClick={() => handleDoneRetestDate(order.retestDate)}
                >
                  <Check />
                </IconButton>
              </Tooltip>
              <Tooltip title="Đóng lịch hẹn khám">
                <IconButton
                  onClick={() => handleCancelRetestDate(order.retestDate)}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </>
          )}
          {!!order.retestDate && (
            <Tooltip title="Chỉnh sửa">
              <IconButton
                onClick={() => {
                  setUpdateReTestDate(order.retestDate);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const handleChangePage = (page) => {
    setPaging((prevState) => ({ ...prevState, page }));
  };

  const handleCloseUpdateDialog = () => {
    setUpdateReTestDate(null);
  };

  const handleUpdatedReTestDate = (reTestDateUpdate) => {
    const newOrders = orders.map((order) => {
      if (order.retestDate?.id !== reTestDateUpdate.id) return order;
      return { ...order, retestDate: reTestDateUpdate };
    });

    setOrders(newOrders);
  };

  useEffect(() => {
    if (!open) return;
    fetchTestingsByCustomerId();
  }, [paging.page, open]);

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="Thông tin chi tiết khách hàng"
      maxWidth="100%"
    >
      <Box padding="12px">
        <StyledCustomer>
          <div className="container">
            <div className="item">
              <b>Tên</b>: {customer.name}
            </div>
            <div className="item">
              <b>Tuổi</b>: {calculateAge(customer.birthday)}
            </div>
            <div className="item">
              <b>Giới tính</b> : {GENDER[customer.gender]}
            </div>
            <div className="item">
              <b>Số điện thoại</b>: {customer.phoneNumber}
            </div>
            <div className="item">
              <b>Địa chỉ</b> : {customer.address}
            </div>
            <div className="item">
              <b>Ngày khám gần nhất: </b>
              {(orders.length &&
                convertFormatDate(orders[0].createdAt, 'HH:mm DD-MM-YYYY')) ||
                'Không có'}
            </div>
            <div className="item">
              <b>Tiền sử bệnh</b> : {customer.anamnesis || 'Không có'}
            </div>
            <div className="item">
              <b>Doanh thu</b>: {delimitNumber(customer.revenue)}
            </div>
          </div>

          <Card title="Đơn khám">
            <CustomTable
              columns={columns}
              data={orders}
              total={paging.total}
              page={paging.page}
              onChangePage={handleChangePage}
            />
          </Card>
        </StyledCustomer>

        <StyledButtonGroup>
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={onClose}
          >
            Đóng
          </Button>
        </StyledButtonGroup>
        <ReTestDateDialog
          open={Boolean(updateReTestDate)}
          reTestDate={updateReTestDate}
          onClose={handleCloseUpdateDialog}
          onUpdateReTestDate={handleUpdatedReTestDate}
        />
      </Box>
    </BaseDialog>
  );
};

export default DetailCustomerDialog;

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Grid, Typography } from '@mui/material';
import { ConfirmDialog, InputControl, ProcessHandler } from '@src/components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { RETEST_DATE_TYPE, TESTING_STEP } from '@src/constants';
import ReTestDatePicker from '@src/components/ReTestDatePicker';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import { useDispatch } from 'react-redux';

import { StyledDiagnoseStep } from './index.style';
import SearchMedicine from './SearchMedicine';
import Prescription from './Prescription';
import { StyledButtonGroup } from '../index.style';

const initialDiagnose = {
  general: '',
  leftEye: '',
  rightEye: '',
};

const initialReTestDate = {
  date: new Date(),
  type: RETEST_DATE_TYPE.SELECT,
  optionValue: 0,
};

const DiagnoseStep = ({
  testing,
  reTestDate: existedReTestDate,
  setReTestDate,
  canUpdateOrder = false,
  onSubmitDiagnose,
  onBackStep,
  isShowDiagnose = true,
  isShowNavigateButton = true,
  onCanUpdateOrder,
  onUpdateOrderTestingProduct,
  onReTestDateChange,
}) => {
  const dispatch = useDispatch();

  const [diagnose, setDiagnose] = useState(initialDiagnose);
  const [medicines, setMedicines] = useState([]);
  const [note, setNote] = useState('');
  const [dialogRetestDate, setDialogReTestDate] = useState(false);
  const [reTestDateObj, setReTestDateObj] = useState(initialReTestDate);

  const handleDateChange = (date, optionValue) => {
    setReTestDateObj((prev) => ({ ...prev, date, optionValue }));
    onReTestDateChange?.((prev) => ({ ...prev, date, optionValue }));
  };

  const handleCloseDialogRetestDate = () => {
    setDialogReTestDate(false);
  };

  const handleTypeReTestDate = (value) => {
    setReTestDateObj((prev) => ({ ...prev, type: value }));
    onReTestDateChange?.((prev) => ({ ...prev, type: value }));
  };

  const handleChangeDiagnose = (e) => {
    const { name, value } = e.target;
    setDiagnose({ ...diagnose, [name]: value });
  };

  const shouldUpdateReTestDate = (existed, nowObj) => {
    if (!existed) return false;
    const isDiff = dayjs(existed.date).diff(nowObj.date, 'day');

    return !(existed.type === nowObj.type && !isDiff);
  };

  const handleSubmitReTestDate = async () => {
    let execFunc;

    const shouldUpdate = shouldUpdateReTestDate(
      existedReTestDate,
      reTestDateObj,
    );

    if (existedReTestDate && shouldUpdate) {
      execFunc = apis.retestDates.updateReTestDate;
    }
    if (!existedReTestDate) {
      execFunc = apis.retestDates.createRetestDate;
    }

    if (!execFunc) return true;

    const payload = {
      ...reTestDateObj,
      customerId: testing.customer?.id,
      testingId: testing.id,
      reTestDateId: existedReTestDate?.id,
      status: existedReTestDate?.status,
    };

    const res = await execFunc(payload);

    if (!res || !res.status) {
      dispatch(
        actions.noti.push({
          severity: 'error',
          code: res?.code || 0,
        }),
      );
      return false;
    }

    setReTestDate(res.result);

    dispatch(
      actions.noti.push({
        message: existedReTestDate
          ? 'Cập nhật tái khám thành công'
          : 'Hẹn tái khám thành công ',
        severity: 'success',
      }),
    );

    return true;
  };

  const handleSelectMedicine = (med) => {
    let isExist = false;
    let tmpMedicines = medicines.map((item) => {
      if (item.name === med.name && item.id === med.id) {
        isExist = true;
        return {
          ...item,
          quantity: `${Number(item.quantity) + 1}`,
        };
      }
      return item;
    });

    if (!isExist)
      tmpMedicines = [
        ...tmpMedicines,
        { id: med.id, name: med.name, quantity: 1, instruction: '' },
      ];

    setMedicines(tmpMedicines);
    onUpdateOrderTestingProduct?.((prev) => ({
      ...prev,
      prescription: tmpMedicines,
    }));
  };

  const handleChangePrescription = (med, { name, value }) => {
    const tmpMedicines = medicines.map((item) => {
      if (item.name === med.name && item.id === med.id)
        return {
          ...item,
          [name]: value,
        };

      return item;
    });

    setMedicines(tmpMedicines);
    onUpdateOrderTestingProduct?.((prev) => ({
      ...prev,
      prescription: tmpMedicines,
    }));
  };

  const handleSubmitDiagnose = async () => {
    if (
      !reTestDateObj.date ||
      !dayjs(reTestDateObj.date).diff(new Date(), 'day')
    ) {
      setDialogReTestDate(true);
      return;
    }

    const shouldContinue = await handleSubmitReTestDate();
    if (!shouldContinue) return;
    onSubmitDiagnose(diagnose, medicines, note);
  };

  const handleContinueStep = () => {
    onSubmitDiagnose(diagnose, medicines, note);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
    onUpdateOrderTestingProduct?.((prev) => ({
      ...prev,
      note: event.target.value,
    }));
  };

  const handleRemoveMedicine = (item) => {
    const tmpMedicines = medicines.filter(
      (med) => med.id !== item.id || med.name !== item.name,
    );

    setMedicines(tmpMedicines);
    onUpdateOrderTestingProduct?.((prev) => ({
      ...prev,
      prescription: tmpMedicines,
    }));
  };

  const initStateByTesting = () => {
    setNote(testing.note || '');
    setDiagnose(testing.diagnose || initialDiagnose);

    const tmpMedicines = testing.prescription.map((item) => ({
      ...item,
      id: item.productId || item.id,
    }));
    setMedicines(tmpMedicines);
  };

  useEffect(() => {
    if (!testing) return;
    initStateByTesting();
  }, [testing]);

  useEffect(() => {
    if (!existedReTestDate) return;
    setReTestDateObj({
      date: existedReTestDate.date,
      type: existedReTestDate.type,
      optionValue: existedReTestDate.optionValue || 0,
    });
  }, [existedReTestDate]);

  return (
    <StyledDiagnoseStep>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} className="left-col">
          {isShowDiagnose && (
            <>
              <Typography className="col-title">Chuẩn đoán</Typography>
              <InputControl
                placeholder="Chẩn đoán chung"
                size="small"
                name="general"
                key="general"
                value={diagnose.general}
                onChange={handleChangeDiagnose}
              />
              <div className="diagnose">
                <InputControl
                  placeholder="Mắt phải"
                  name="rightEye"
                  key="rightEye"
                  size="small"
                  value={diagnose.rightEye}
                  onChange={handleChangeDiagnose}
                />
                <InputControl
                  placeholder="Mắt trái"
                  name="leftEye"
                  key="leftEye"
                  size="small"
                  value={diagnose.leftEye}
                  onChange={handleChangeDiagnose}
                />
              </div>
            </>
          )}
          <SearchMedicine onPickMedicine={handleSelectMedicine} />
        </Grid>
        <Grid item xs={12} md={7} className="right-col">
          <Typography className="col-title">Thuốc</Typography>
          <Prescription
            medicines={medicines}
            canUpdateOrder={canUpdateOrder}
            onCanUpdateOrder={onCanUpdateOrder}
            onChange={handleChangePrescription}
            onRemove={handleRemoveMedicine}
          />

          <div className="reTestDate-Picker">
            <ReTestDatePicker
              title={
                existedReTestDate
                  ? 'Cập nhật ngày tái khám'
                  : 'Chọn ngày tái khám'
              }
              type={reTestDateObj.type}
              dateValue={reTestDateObj.date}
              optionValue={reTestDateObj.optionValue}
              onTypeChange={handleTypeReTestDate}
              onDateChange={handleDateChange}
            />
          </div>

          <InputControl
            label="Ghi chú"
            multiline
            name="note"
            className="note"
            value={note}
            placeholder="Ghi chú thêm ..."
            onChange={handleNoteChange}
          />

          {isShowNavigateButton && (
            <StyledButtonGroup>
              <Button
                size="small"
                variant="outlined"
                onClick={() => onBackStep(TESTING_STEP.TEST)}
              >
                Quay lại
              </Button>
              <Button
                size="small"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={handleSubmitDiagnose}
              >
                <ProcessHandler loading={false}>Tiếp theo</ProcessHandler>
              </Button>
            </StyledButtonGroup>
          )}
        </Grid>
      </Grid>

      <ConfirmDialog
        open={dialogRetestDate}
        title="Chưa chọn ngày tái khám"
        description="Bạn chưa chọn ngày tái khám, Vẫn tiếp tục?"
        titleOk="Đồng ý"
        titleCancel="Hủy"
        onClose={handleCloseDialogRetestDate}
        onOk={handleContinueStep}
        onCancel={handleCloseDialogRetestDate}
      />
    </StyledDiagnoseStep>
  );
};

export default DiagnoseStep;
